import React from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import camp from "../../img/camp.jpg";
import ecommerce from "../../img/mern.jpg";
import covid from "../../img/COVID PROJECT.png";
import arch from "../../img/arch2.jpg";
import { themeContext } from "../../Context";
import { useContext } from "react";

const Portfolio = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="portfolio" id="projects">
      <span style={{ color: darkMode ? "white" : "" }}>My Portfolio</span>
      <span>Recent Projects</span>
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
<a href="https://ancient-spire-44655.herokuapp.com/">
          <img src={camp} alt="" /></a>
        </SwiperSlide>
        <SwiperSlide>
<a href="https://github.com/fshuai822/E-shop">
          <img src={ecommerce} alt="" />
</a>
        </SwiperSlide>
        <SwiperSlide>
          <img src={covid} alt="" />
        </SwiperSlide>
        <SwiperSlide>
<a href="https://issuu.com/shuaifeng9578/docs/portfolio-2">
          <img src={arch} alt="" />
</a>
        </SwiperSlide>

{/* <div className="i-icons">
          <a href="https://github.com/fshuai822">
            <img src={Github} alt="" />
          </a>
          <a href="https://www.linkedin.com/in/shuai-feng">
            <img src={LinkedIn} alt="" />
          </a>
          <a href="https://www.instagram.com/sfeng_22/">
            <img src={Instagram} alt="" />
          </a>
        </div> */}

      </Swiper>
    </div>
  );
};

export default Portfolio;
